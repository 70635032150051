@import '~@/assets/scss/main.scss';

.card-title {
    text-align: center;
    font-weight: bold;
}
.card-text {
    text-align: center;
}

.btn-bottom {
    border-radius: 0rem 0rem $border-radius-card $border-radius-card;
    color: white;
    background-color: v-bind(borderColorHex);
    transition: all $transition-duration;
    &:hover {
        filter: brightness(110%);
    }
}

.card-text-courses {
    min-height: 6rem;
}

.content-card-content {
    padding: 1rem;
    text-align: center;
}
