@import '~@/assets/scss/main.scss';

.sticker-sizing {
    $size: 8rem;
    height: $size;
    width: $size;
}

.circle {
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
    color: white;
    @extend .font-raleway;
    @extend .font-weight-bold;
    @extend .sticker-sizing;
}

#new-stickers-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem 1.5rem;

    div {
        min-width: 5rem;
        max-width: 8rem;
        text-align: center;
    }
}
